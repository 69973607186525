<template>
  <div class="content-div content-div-1">
    <div class="header">
      <h1>Application for Renewal of a Liquor Licence</h1>
      <div class="d-flex align-center ">
        <BoldLine :options="{color:this.$vuetify.theme.themes.light.secondary,height:'auto',width:'6px'}" :spacing="'x-stretch'"></BoldLine>
        <h2>Information Relating to Management</h2>
      </div>
    </div>
    <div class="body">
      <div class="form-bg-circle" >4</div>
      <div class="form-div">
        <v-form v-model="form.valid" >
            <h5>11 .  Business and Management Related Information</h5>
            <h5 class="mt-3 ml-7">a. Opening hours are </h5>
            <div class="ml-11"><h5 class="my-0 ml-2 fw-400 em-18">Please state in 24-hour format </h5></div>
            <div class="form-section ml-11 justify-start align-center pt-3">
               <div class="d-flex flex-column">
                    <h5 class="my-0 ml-2 fw-400 em-16 mb-2">From </h5>
                    <div class="d-flex align-baseline">
                      <v-combobox v-model="management_data.openingHrs[0].from[0]" class="ml-2 tf-multiple time" :ref="'11_a_from_h'"  maxlength=2  @keyup="timeTFfocusNext('11_a_from_h','11_a_from_m')" :items="timebox.hours" outlined>
                        <template v-slot:append>
                          <div></div>
                        </template>
                      </v-combobox>
                      <div>:</div>
                      <v-combobox v-model="management_data.openingHrs[0].from[1]" class="ml-2 tf-multiple time" :ref="'11_a_from_m'"  maxlength=2  @keyup="timeTFfocusNext('11_a_from_m','11_a_to_h')" :items="timebox.mins" outlined>
                        <template v-slot:append>
                          <div></div>
                        </template>
                      </v-combobox>
                    </div>
                </div>
                <div class="mx-3">-</div>
                <div class="d-flex flex-column ">
                    <h5 class="my-0 ml-2 fw-400 em-16 mb-2">To </h5>
                    <div class="d-flex align-baseline">
                      <v-combobox v-model="management_data.openingHrs[0].to[0]" class="ml-2 tf-multiple time" :ref="'11_a_to_h'"  maxlength=2  @keyup="timeTFfocusNext('11_a_to_h','11_a_to_m')" :items="timebox.hours" outlined>
                        <template v-slot:append>
                          <div></div>
                        </template>
                      </v-combobox>
                      <div>:</div>
                      <v-combobox v-model="management_data.openingHrs[0].to[1]" class="ml-2 tf-multiple time" :ref="'11_a_to_m'"  maxlength=2  :items="timebox.mins" outlined>
                        <template v-slot:append>
                          <div></div>
                        </template>
                      </v-combobox>
                    </div>
                </div>
            </div>
            <div class="form-section ml-11 justify-start align-center pb-3 mt-n2">
               <div class="d-flex flex-column">
                    <h5 class="my-0 ml-2 fw-400 em-16 mb-2">From </h5>
                    <div class="d-flex align-baseline">
                      <v-combobox v-model="management_data.openingHrs[1].from[0]" class="ml-2 tf-multiple time" :ref="'11_a_from_h2'"  maxlength=2  @keyup="timeTFfocusNext('11_a_from_h2','11_a_from_m2')" :items="timebox.hours" outlined>
                        <template v-slot:append>
                          <div></div>
                        </template>
                      </v-combobox>
                      <div>:</div>
                      <v-combobox v-model="management_data.openingHrs[1].from[1]" class="ml-2 tf-multiple time" :ref="'11_a_from_m2'"  maxlength=2  @keyup="timeTFfocusNext('11_a_from_m2','11_a_to_h2')" :items="timebox.mins" outlined>
                        <template v-slot:append>
                          <div></div>
                        </template>
                      </v-combobox>
                    </div>
                </div>
                <div class="mx-3 ">-</div>
                <div class="d-flex flex-column ">
                    <h5 class="my-0 ml-2 fw-400 em-16 mb-2">To </h5>
                    <div class="d-flex align-baseline">
                      <v-combobox v-model="management_data.openingHrs[1].to[0]" class="ml-2 tf-multiple time" :ref="'11_a_to_h2'"  maxlength=2  @keyup="timeTFfocusNext('11_a_to_h2','11_a_to_m2')" :items="timebox.hours" outlined>
                        <template v-slot:append>
                          <div></div>
                        </template>
                      </v-combobox>
                      <div>:</div>
                      <v-combobox v-model="management_data.openingHrs[1].to[1]" class="ml-2 tf-multiple time" :ref="'11_a_to_m2'"  maxlength=2  :items="timebox.mins" outlined>
                        <template v-slot:append>
                          <div></div>
                        </template>
                      </v-combobox>
                    </div>
                </div>
            </div>
            <h5 class="mt-3 ml-7">b. Liquor selling hours are </h5>
            <div class="ml-11"><h5 class="my-0 ml-2 fw-400 em-18">Please state in 24-hour format </h5></div>
            <div class="form-section ml-11 justify-start align-center pt-3">
               <div class="d-flex flex-column">
                    <h5 class="my-0 ml-2 fw-400 em-16 mb-2">From </h5>
                    <div class="d-flex align-baseline">
                      <v-combobox v-model="management_data.sellingHrs[0].from[0]" class="ml-2 tf-multiple time" :ref="'11_b_from_h'"  maxlength=2  @keyup="timeTFfocusNext('11_b_from_h','11_b_from_m')" :items="timebox.hours" outlined>
                        <template v-slot:append>
                          <div></div>
                        </template>
                      </v-combobox>
                      <div>:</div>
                      <v-combobox v-model="management_data.sellingHrs[0].from[1]" class="ml-2 tf-multiple time" :ref="'11_b_from_m'"  maxlength=2 @keyup="timeTFfocusNext('11_b_from_m','11_b_to_h')" :items="timebox.mins" outlined>
                        <template v-slot:append>
                          <div></div>
                        </template>
                      </v-combobox>
                    </div>
                </div>
                <div class="mx-3">-</div>
                <div class="d-flex flex-column ">
                    <h5 class="my-0 ml-2 fw-400 em-16 mb-2">To </h5>
                    <div class="d-flex align-baseline">
                      <v-combobox v-model="management_data.sellingHrs[0].to[0]" class="ml-2 tf-multiple time" :ref="'11_b_to_h'"  maxlength=2  @keyup="timeTFfocusNext('11_b_to_h','11_b_to_m')" :items="timebox.hours" outlined>
                        <template v-slot:append>
                          <div></div>
                        </template>
                      </v-combobox>
                      <div>:</div>
                      <v-combobox v-model="management_data.sellingHrs[0].to[1]" class="ml-2 tf-multiple time" :ref="'11_b_to_m'"  maxlength=2  :items="timebox.mins" outlined>
                        <template v-slot:append>
                          <div></div>
                        </template>
                      </v-combobox>
                    </div>
                </div>
            </div>
            <div class="form-section ml-11 justify-start align-center pb-3 mt-n2">
               <div class="d-flex flex-column">
                    <h5 class="my-0 ml-2 fw-400 em-16 mb-2">From </h5>
                    <div class="d-flex align-baseline">
                      <v-combobox v-model="management_data.sellingHrs[1].from[0]" class="ml-2 tf-multiple time" :ref="'11_b_from_h2'"  maxlength=2  @keyup="timeTFfocusNext('11_b_from_h2','11_b_from_m2')" :items="timebox.hours" outlined>
                        <template v-slot:append>
                          <div></div>
                        </template>
                      </v-combobox>
                      <div>:</div>
                      <v-combobox v-model="management_data.sellingHrs[1].from[1]" class="ml-2 tf-multiple time" :ref="'11_b_from_m2'"  maxlength=2  @keyup="timeTFfocusNext('11_b_from_m2','11_b_to_h2')" :items="timebox.mins" outlined>
                        <template v-slot:append>
                          <div></div>
                        </template>
                      </v-combobox>
                    </div>
                </div>
                <div class="mx-3 ">-</div>
                <div class="d-flex flex-column ">
                    <h5 class="my-0 ml-2 fw-400 em-16 mb-2">To </h5>
                    <div class="d-flex align-baseline">
                      <v-combobox v-model="management_data.sellingHrs[1].to[0]" class="ml-2 tf-multiple time" :ref="'11_b_to_h2'"  maxlength=2  @keyup="timeTFfocusNext('11_b_to_h2','11_b_to_m2')" :items="timebox.hours" outlined>
                        <template v-slot:append>
                          <div></div>
                        </template>
                      </v-combobox>
                      <div>:</div>
                      <v-combobox v-model="management_data.sellingHrs[1].to[1]" class="ml-2 tf-multiple time" :ref="'11_b_to_m2'"  maxlength=2  :items="timebox.mins" outlined>
                        <template v-slot:append>
                          <div></div>
                        </template>
                      </v-combobox>
                    </div>
                </div>
            </div>
            <h5 class="mt-3 ml-7">c. The peak business hours are </h5>
            <div class="ml-11"><h5 class="my-0 ml-2 fw-400 em-18">Please state in 24-hour format </h5></div>
            <div class="form-section ml-11 justify-start align-center pt-3">
               <div class="d-flex flex-column">
                    <h5 class="my-0 ml-2 fw-400 em-16 mb-2">From </h5>
                    <div class="d-flex align-baseline">
                      <v-combobox v-model="management_data.peakHrs[0].from[0]" class="ml-2 tf-multiple time" :ref="'11_c_from_h'"  maxlength=2  @keyup="timeTFfocusNext('11_c_from_h','11_c_from_m')" :items="timebox.hours" outlined>
                        <template v-slot:append>
                          <div></div>
                        </template>
                      </v-combobox>
                      <div>:</div>
                      <v-combobox v-model="management_data.peakHrs[0].from[1]" class="ml-2 tf-multiple time" :ref="'11_c_from_m'"  maxlength=2  @keyup="timeTFfocusNext('11_c_from_m','11_c_to_h')" :items="timebox.mins" outlined>
                        <template v-slot:append>
                          <div></div>
                        </template>
                      </v-combobox>
                    </div>
                </div>
                <div class="mx-3">-</div>
                <div class="d-flex flex-column ">
                    <h5 class="my-0 ml-2 fw-400 em-16 mb-2">To </h5>
                    <div class="d-flex align-baseline">
                      <v-combobox v-model="management_data.peakHrs[0].to[0]" class="ml-2 tf-multiple time" :ref="'11_c_to_h'"  maxlength=2  @keyup="timeTFfocusNext('11_c_to_h','11_c_to_m')" :items="timebox.hours" outlined>
                        <template v-slot:append>
                          <div></div>
                        </template>
                      </v-combobox>
                      <div>:</div>
                      <v-combobox v-model="management_data.peakHrs[0].to[1]" class="ml-2 tf-multiple time" :ref="'11_c_to_m'"  maxlength=2  :items="timebox.mins" outlined>
                        <template v-slot:append>
                          <div></div>
                        </template>
                      </v-combobox>
                    </div>
                </div>
            </div>
            <div class="form-section ml-11 justify-start align-center pb-3 mt-n2">
               <div class="d-flex flex-column">
                    <h5 class="my-0 ml-2 fw-400 em-16 mb-2">From </h5>
                    <div class="d-flex align-baseline">
                      <v-combobox v-model="management_data.peakHrs[1].from[0]" class="ml-2 tf-multiple time" :ref="'11_c_from_h2'"  maxlength=2  @keyup="timeTFfocusNext('11_c_from_h2','11_c_from_m2')" :items="timebox.hours" outlined>
                        <template v-slot:append>
                          <div></div>
                        </template>
                      </v-combobox>
                      <div>:</div>
                      <v-combobox v-model="management_data.peakHrs[1].from[1]" class="ml-2 tf-multiple time" :ref="'11_c_from_m2'"  maxlength=2  @keyup="timeTFfocusNext('11_c_from_m2','11_c_to_h2')" :items="timebox.mins" outlined>
                        <template v-slot:append>
                          <div></div>
                        </template>
                      </v-combobox>
                    </div>
                </div>
                <div class="mx-3 ">-</div>
                <div class="d-flex flex-column ">
                    <h5 class="my-0 ml-2 fw-400 em-16 mb-2">To </h5>
                    <div class="d-flex align-baseline">
                      <v-combobox v-model="management_data.peakHrs[1].to[0]" class="ml-2 tf-multiple time" :ref="'11_c_to_h2'"  maxlength=2  @keyup="timeTFfocusNext('11_c_to_h2','11_c_to_m2')" :items="timebox.hours" outlined>
                        <template v-slot:append>
                          <div></div>
                        </template>
                      </v-combobox>
                      <div>:</div>
                      <v-combobox v-model="management_data.peakHrs[1].to[1]" class="ml-2 tf-multiple time" :ref="'11_c_to_m2'"  maxlength=2  :items="timebox.mins" outlined>
                        <template v-slot:append>
                          <div></div>
                        </template>
                      </v-combobox>
                    </div>
                </div>
            </div>
            <h5 class="mt-2">12 . Your duty </h5>
            <h5 class="mt-3 ml-7">a. Your duty hours (in 24-hour format,  max 2 different sessions) </h5>
            <div class="form-section ml-11 justify-start align-center pt-3">
               <div class="d-flex flex-column">
                    <h5 class="my-0 ml-2 fw-400 em-16 mb-2">From </h5>
                    <div class="d-flex align-baseline">
                      <v-combobox v-model="management_data.dutyHrs[0].from[0]" class="ml-2 tf-multiple time" :ref="'12_a_from_h'"  maxlength=2  @keyup="timeTFfocusNext('12_a_from_h','12_a_from_m')" :items="timebox.hours" outlined>
                        <template v-slot:append>
                          <div></div>
                        </template>
                      </v-combobox>
                      <div>:</div>
                      <v-combobox v-model="management_data.dutyHrs[0].from[1]" class="ml-2 tf-multiple time" :ref="'12_a_from_m'"  maxlength=2  @keyup="timeTFfocusNext('12_a_from_m','12_a_to_h')" :items="timebox.mins" outlined>
                        <template v-slot:append>
                          <div></div>
                        </template>
                      </v-combobox>
                    </div>
                </div>
                <div class="mx-3">-</div>
                <div class="d-flex flex-column ">
                    <h5 class="my-0 ml-2 fw-400 em-16 mb-2">To </h5>
                    <div class="d-flex align-baseline">
                      <v-combobox v-model="management_data.dutyHrs[0].to[0]" class="ml-2 tf-multiple time" :ref="'12_a_to_h'"  maxlength=2  @keyup="timeTFfocusNext('12_a_to_h','12_a_to_m')" :items="timebox.hours" outlined>
                        <template v-slot:append>
                          <div></div>
                        </template>
                      </v-combobox>
                      <div>:</div>
                      <v-combobox v-model="management_data.dutyHrs[0].to[1]" class="ml-2 tf-multiple time" :ref="'12_a_to_m'"  maxlength=2  :items="timebox.mins" outlined>
                        <template v-slot:append>
                          <div></div>
                        </template>
                      </v-combobox>
                    </div>
                </div>
            </div>
            <div class="form-section ml-11 justify-start align-center pb-3 mt-n2">
               <div class="d-flex flex-column">
                    <h5 class="my-0 ml-2 fw-400 em-16 mb-2">From </h5>
                    <div class="d-flex align-baseline">
                      <v-combobox v-model="management_data.dutyHrs[1].from[0]" class="ml-2 tf-multiple time" :ref="'12_a_from_h2'"  maxlength=2  @keyup="timeTFfocusNext('12_a_from_h2','12_a_from_m2')" :items="timebox.hours" outlined>
                        <template v-slot:append>
                          <div></div>
                        </template>
                      </v-combobox>
                      <div>:</div>
                      <v-combobox v-model="management_data.dutyHrs[1].from[1]" class="ml-2 tf-multiple time" :ref="'12_a_from_m2'"  maxlength=2  @keyup="timeTFfocusNext('12_a_from_m2','12_a_to_h2')" :items="timebox.mins" outlined>
                        <template v-slot:append>
                          <div></div>
                        </template>
                      </v-combobox>
                    </div>
                </div>
                <div class="mx-3 ">-</div>
                <div class="d-flex flex-column ">
                    <h5 class="my-0 ml-2 fw-400 em-16 mb-2">To </h5>
                    <div class="d-flex align-baseline">
                      <v-combobox v-model="management_data.dutyHrs[1].to[0]" class="ml-2 tf-multiple time" :ref="'12_a_to_h2'"  maxlength=2  @keyup="timeTFfocusNext('12_a_to_h2','12_a_to_m2')" :items="timebox.hours" outlined>
                        <template v-slot:append>
                          <div></div>
                        </template>
                      </v-combobox>
                      <div>:</div>
                      <v-combobox v-model="management_data.dutyHrs[1].to[1]" class="ml-2 tf-multiple time" :ref="'12_a_to_m2'"  maxlength=2  :items="timebox.mins" outlined>
                        <template v-slot:append>
                          <div></div>
                        </template>
                      </v-combobox>
                    </div>
                </div>
            </div>
            <h5 class="mt-3 ml-7">b. Your weekly day-off is </h5>
            <div class="form-section ml-12 flex-column">
              <v-checkbox v-model="management_data.weeklyDayoff.days" class="o-cb mt-2 mb-n6" on-icon="mdi-check-box-outline" label="Monday" value="Monday"></v-checkbox>
              <v-checkbox v-model="management_data.weeklyDayoff.days" class="o-cb mb-n6" on-icon="mdi-check-box-outline" label="Tuesday" value="Tuesday"></v-checkbox>
              <v-checkbox v-model="management_data.weeklyDayoff.days" class="o-cb mb-n6" on-icon="mdi-check-box-outline" label="Wednesday" value="Wednesday"></v-checkbox>
              <v-checkbox v-model="management_data.weeklyDayoff.days" class="o-cb mb-n6" on-icon="mdi-check-box-outline" label="Thursday" value="Thursday"></v-checkbox>
              <v-checkbox v-model="management_data.weeklyDayoff.days" class="o-cb mb-n6" on-icon="mdi-check-box-outline" label="Friday" value="Friday"></v-checkbox>
              <v-checkbox v-model="management_data.weeklyDayoff.days" class="o-cb mb-n6" on-icon="mdi-check-box-outline" label="Saturday" value="Saturday"></v-checkbox>
              <v-checkbox v-model="management_data.weeklyDayoff.days" class="o-cb mb-n6" on-icon="mdi-check-box-outline" label="Sunday" value="Sunday"></v-checkbox>
              <div class="d-flex flex-wrap">
                <v-checkbox class="o-cb mr-3" v-model="checkbox_selected" on-icon="mdi-check-box-outline" label="Other, no fix day" value="Other"></v-checkbox>
                <v-text-field v-model="management_data.weeklyDayoff.other"  class="tf-half" :rules="form.fullnameRules"  label="Please specify" outlined required></v-text-field>
              </div>
            </div>
            <div class="mt-1 mb-4 ml-7 d-flex align-center">
              <h5 class="mt-3">c. Will any nominee be appointed?  </h5>
              <v-tooltip right>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon class="mb-1 ml-3 " color="secondary" dark v-bind="attrs" v-on="on">
                    mdi-help-circle
                  </v-icon>
                </template>
                <div>
                  <div class="d-flex align-center mb-2">
                    <v-icon color="secondary" dark >
                      mdi-help-circle
                    </v-icon>
                    <h3 class="mb-0">Tips:</h3>
                  </div>
                  <div class="ml-8 c-div">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.
                  </div>
                </div>
              </v-tooltip>
            </div>
            <div class="form-section ml-11">
              <v-radio-group v-model="management_data.q12c" class="ml-2 mt-n3" row>
                <v-radio class="row-radio" :label="'Yes'" :value="true"></v-radio>
                <v-radio class="row-radio ml-3" :label="'No'" :value="false"></v-radio>
              </v-radio-group>
            </div>
            <h3>Information of Nominated Reserve Licensee</h3>
            <div class="callout-div mt-5">
              <p class="mb-0"><strong>Reminder</strong> : You can nominate a reserve licensee in this application </p>
            </div>
            <div class=" d-flex flex-column mt-8">
              <div class="f-li d-flex">
                <p></p>
                <p>In case the liquor licence holder is unable to manage the premises (for example, due to illness or leave), resigns from the post or applies for cancellation of the liquor licence, the nominated reserve licensee can be the applicant for the issue of a new liquor licence in respect of the premises</p>
              </div>
              <div class="f-li d-flex mt-3">
                <p>ii.</p>
                <p>In case the liquor licence holder is unable to manage the premises (for example, due to illness or leave), resigns from the post or applies for cancellation of the liquor licence, the nominated reserve licensee can be the applicant for the issue of a new liquor licence in respect of the premises</p>
              </div>
            </div>
            <div class="mt-1 mb-4 d-flex align-center flex-wrap">
              <h5 class="my-0">13 . Will you nominate a reserve licensee?</h5>
              <v-radio-group v-model="management_data.q13" class="ml-15" row>
                <v-radio class="row-radio" :label="'Yes'" :value="true"></v-radio>
                <v-radio class="row-radio ml-3" :label="'No'" :value="false"></v-radio>
              </v-radio-group>
            </div>
        </v-form>
      </div>
    </div>
    <div class="footer">
      <v-btn class="footer-btn b-dark">Save Draft</v-btn>
      <v-btn class="footer-btn b-primary" :disabled="false">Save and Next</v-btn>
    </div>
  </div>
</template>

<script>
import BoldLine from '../../components/misc/bold-line'
import MultipleTextfield from '../../components/misc/multiple-textfield'
// @ is an alias to /src


export default {
  name: 'RenewalIM',
  components: {
    BoldLine,
    MultipleTextfield
  },
  data: function () {
    return {
      agree: false,
      checkbox_selected : [],
      timebox:{
        hours:['00','01','02','03','04','05','06','07','08','09','10','11','12','13','14','15','16','17','18','19','20','21','22','23','24'],
        mins:['00','05','10','15','20','25','30','35','40','45','50','55']
      },
      form :{
        valid:false,
        fullnameRules: [
            v => !!v || 'Required',
        ],
        emailRules: [
            v => !!v || 'Email is required',
            v => /.+@.+/.test(v) || 'Email must be valid',
        ]
      },
      management_data:{
        openingHrs:[{from:['12','00'],to:['16','00']},{from:['18','00'],to:['00','00']}],
        sellingHrs:[{from:['12','00'],to:['16','00']},{from:['18','00'],to:['00','00']}],
        peakHrs:[{from:['12','00'],to:['16','00']},{from:['18','00'],to:['00','00']}],
        dutyHrs:[{from:['12','00'],to:['16','00']},{from:['18','00'],to:['00','00']}],
        weeklyDayoff:{days:['Tuesday'],other:''},
        q12c:false,
        q13:false,
      },
      region:{
        tc:['香港','九龍','新界'],
        en:['Hong Kong','Kowloon','New Territories']
      },
      premisesBrMTF:{
        value : '',
        refID: 'premisesBr',
        error :{show:false,msg:'Invalid input'},
        amount: 11,
        connect: [7],
      }
    }
  },
  methods:{
    updatePremisesBrMTF : function (inValue,i){
      this.updateMTF(inValue,i,this.premisesBrMTF);
    },
    updateMTF : function (inValue,i,target){
      var str = this.joinStringWithMTF(inValue,target.connect)
      target.value = str;
      const lastindex = target.amount-1;
      if(i == lastindex){
        if(str.length < (target.amount+target.connect.length)){
          target.error.show = true;
        }else{
          target.error.show = false;
        }
      }
    },
    joinStringWithMTF: function (inValue,connect){
      const temp = []
      inValue.forEach((element,i) => {
        if(element != null && element !=''){
          temp[temp.length] = element;
        }
      });
      if(connect.length>0){
        var n = 1;
        connect.forEach(i => {
          if(temp.length>i+n){
            console.log(temp.length);
            temp.splice(i+n,0, '-');
            n++;
          }
        });
      }
      const str = temp.join('')
      return str;
    },
    timeTFfocusNext(self,target){
      if((this.$refs[self].$refs.input.value + '').length ==2){
        this.$refs[self].blur();
        this.$refs[target].focus();
      }
    }
  },
  mounted(){
    this.$emit('updateCurrent',4);
    this.dialog = true;
  },
}
</script>

<style scoped lang="scss">

.main-container {
    width:100%;
    height:100%;
    flex-direction: row;
    align-items: flex-start;
}

.form-div{
    width:clamp(280px, 100%, 666px);
    .o-btn-action{
        margin-top:32.5px;
    }
}

h5{
  color:black;
}

h5.fw-400{
  color:$ols-t-dark;
  margin-top:13px;
  margin-bottom:40px;
}

h5.em-16 {
  font-size: 1em;
}

h5.em-18{
  font-size: 1.125em;
}

.big-gap{
  margin-right:16px
}

//md and down
@media screen and (max-width: 1263px) {

.main-container {
    width:100%;
    height:100%;
    flex-direction: column;
}

h5.em-18{
  font-size: 1em;
}

.big-gap{
  margin-right:8px
}

}

</style>
